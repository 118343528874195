import dayjs from 'dayjs'
import camelCase from 'lodash/camelCase.js'

export const MILLIS = 1

export const SECONDS = 1000 * MILLIS

export const MINUTES = 60 * SECONDS

export const HOURS = 60 * MINUTES

export const DAYS = 24 * HOURS

// TODO(113168): fix: import from @nsf/core/Utils  throws error with reading config
const isNullish = (o) => o === null || o === undefined

const isArray = (o) => Array.isArray(o)

const isFunction = (o) => typeof o === 'function'

const isObject = (o) => o === Object(o) && !isArray(o) && !isFunction(o)

/**
 * Takes a number which represent days from now (positive or negative) and make them
 * to fit 0 through 6 (i.e. monday to sunday).
 *
 * @param {number} day
 * @returns {number}
 */
export const wrapWeek = (day) => day % 7

/**
 * Takes id of today and returns ids of following days, including today.
 *
 * E.g. today is saturday, i.e. 6. We want 3 days. We receive 6 (sat), 7 (sun), 1 (mon).
 *
 * @param count
 * @returns {[]}
 */
export const daysIdsStartingToday = (count) => {
  const today = dayjs().day()

  const days = []

  for (let i = 0; i < count; i++) {
    const followingDay = today + i

    days.push(wrapWeek(followingDay))
  }

  return days
}

/**
 * Normalize Sunday to 0 instead of 7.
 *
 * @param day {number|string}
 * @returns {number|string}
 */
export const normalizeSunday = (day) => (Number(day) === 7 ? 0 : day)

export const isSameDayPickupAvailable = (isReservation, openHours) => {
  if (!isReservation || !openHours.length) {
    return false
  }

  const currentDate = dayjs().format('YYYY-MM-DD')
  const openHoursToday = openHours.find((day) => day.date === currentDate)

  if (!openHoursToday?.close) {
    return false
  }

  const now = dayjs()
  const closesAt = dayjs(`${currentDate}T${openHoursToday.close}`)
  const difference = closesAt.diff(now, 'minute')

  return difference >= 60
}

/**
 * Checks if the given date is less than 24 hours from now.
 *
 * @param {Date} date
 * @returns {boolean}
 */
export const isLessThan24Hours = (date) => {
  const future = new Date(new Date().getTime() + 1 * DAYS)

  return date <= future
}

export const setState = (state, newState) => {
  for (const [key, value] of Object.entries(newState)) {
    state[key] = value
  }
}

export const STOCK_STATUSES = {
  UNKNOWN: 'UNKNOWN',
  LOADING: 'LOADING',
  IN_STOCK: 'IN_STOCK',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
}

const currencyLocaleConfig = {
  RON: 'ro-RO',
  CZK: 'cs-CZ',
  EUR: 'sk-SK',
  PLN: 'pl-PL',
  // TODO - add IT
}

const defaultCurrency = 'CZK' // TODO - fix this

function getDecimalNumbersForCurrency(currency) {
  switch (currency) {
    case 'CZK':
      return 0
    case 'EUR':
    case 'RON':
    case 'PLN':
      return 2
    default:
      return 2
  }
}

function localizePrice(value, currency, customDecimals) {
  const localizeCurrency = currency || defaultCurrency
  const decimalNumbers = getDecimalNumbersForCurrency(localizeCurrency)

  const options = {
    style: 'decimal',
    currency: localizeCurrency,
    minimumFractionDigits: customDecimals.length ? customDecimals[1] || customDecimals[0] : decimalNumbers,
    maximumFractionDigits: customDecimals.length ? customDecimals[0] : decimalNumbers,
  }

  const currencyLocale = currencyLocaleConfig[localizeCurrency]
  const numberFormat = new Intl.NumberFormat(currencyLocale || 'en-US', options)
  return numberFormat.format(value)
}

export const getCurrency = (value, currency) => {
  const price = Number(value)

  switch (currency) {
    case 'CZK': return 'Kč'
    case 'PLN': return 'zł'
    case 'EUR': return '€'
    case 'RON':
      if (price === 1) {
        return 'Leu'
      }
      return 'Lei'

    default: return currency
  }
}

/**
 * Specifies custom decimal settings.
 *
 * @typedef {number[]} CustomDecimals
 * @property {number} 0 - The maximum decimal value.
 * @property {number} [1] - The minimum decimal value. If not specified, the maximum value is used.
 */

/**
 * Currency-based price formatter
 *
 * @param {string | number} value
 * @param {string} currency
 * @param {boolean} addCurrency
 * @param {CustomDecimals} customDecimals - An array containing the minimum and maximum decimal values.
 * @returns {string}
 */

export const formatPrice = (value, currency, addCurrency = true, customDecimals = []) => {
  const price = Number(value)

  if (currency) {
    return `${localizePrice(price, currency, customDecimals)} ${addCurrency ? getCurrency(price, currency) : ''}`
  }
  return localizePrice(price, null, customDecimals)
}

export const mergeWithoutDuplicates = (...arrays) => [...new Set(arrays.flat())]

export const loadScript = (src) => new Promise((resolve, reject) => {
  if (document.querySelector(`script[src="${src}"]`)) {
    resolve()

    return
  }

  const el = document.createElement('script')

  el.setAttribute('type', 'text/javascript')
  el.setAttribute('async', true)
  el.setAttribute('src', src)

  const loadCallback = () => {
    resolve()
    el.removeEventListener('load', loadCallback)
  }

  const errorCallback = () => {
    reject(new Error(`Error while loading '${src}'`))
    el.removeEventListener('error', errorCallback)
  }

  const abortCallback = () => {
    reject(new Error(`Loading of '${src}' was aborted`))
    el.removeEventListener('abort', abortCallback)
  }

  el.addEventListener('load', loadCallback)
  el.addEventListener('error', errorCallback)
  el.addEventListener('abort', abortCallback)

  document.head.appendChild(el)
})

export const slugify = (text) => text.toLowerCase()
  .replace(/\s+/g, '-') // Replace spaces with -
  .replace(/[^\w-]+/g, '') // Remove all non-word chars
  .replace(/--+/g, '-') // Replace multiple - with single -
  .replace(/^-+/, '') // Trim - from start of text
  .replace(/-+$/, '') // Trim - from end of text

export const objectToQuery = (object) => Object.keys(object)
  .filter((key) => object[key] !== undefined)
  .map((key) => {
    if (isArray(object[key])) {
      return object[key]
        .map((value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&')
    } if (isObject(object[key])) {
      return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(object[key]))}`
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`
  })
  .join('&')

/**
 * Degrees to radians ratio
 *
 * @type {number}
 */
const DEG_TO_RAD = Math.PI / 180

/**
 * Km per degree of latitude/longitude
 *
 * @type {number}
 */
const DEG_LEN = 110.25

/**
 * Calculate distance between two locations
 *  - naive but fast - Pythagorean theorem
 *  - for country level it's just about ca. 0.5 % less accurate than haversine formula
 *
 * @param {Object} locationA
 * @param {Object} locationB
 *
 * @return {number} Distance in km.
 */
export const getDistance = (locationA, locationB) => {
  const x = locationA.latitude - locationB.latitude
  const y = (locationA.longitude - locationB.longitude) * Math.cos(locationB.latitude * DEG_TO_RAD)

  return Math.sqrt(x ** 2 + y ** 2) * DEG_LEN
}

/**
 * Get type of marker cluster
 * @param markers markers in cluster
 * @returns {string} type of pickup places in cluster, default for mixed types
 */
export const getClusterType = (markers) => {
  if (!markers.length || !markers[0].icon?.url) {
    return 'default'
  }

  const markerUrl = markers[0].icon.url

  if (markerUrl.includes('pharmacy')) {
    return 'pharmacy'
  }
  if (markerUrl.includes('post')) {
    return 'postOffice'
  }
  if (markerUrl.includes('packeta')) {
    return 'packeta'
  }
  if (markerUrl.includes('paczkomaty') || markerUrl.includes('paczkomat')) {
    return 'paczkomat'
  }
  if (markerUrl.includes('inpost')) {
    return 'inpost'
  }
  if (markerUrl.includes('boxes') || markerUrl.includes('drmax_box')) {
    return 'drmax_box'
  }

  return 'default'
}

/**
 * Get type of place returned from Google Places API, that determines suitable zoom level of the map
 * @param googlePlaceTypes place types that given place has - https://developers.google.com/maps/documentation/places/web-service/supported_types#table2
 * @returns {string} camel-cased place type relevant for figuring out suitable zoom level
 */
export const getGooglePlaceTypeRelevantForZoomLevel = (...googlePlaceTypes) => {
  const firstType = googlePlaceTypes.pop()
  if (firstType === 'political') {
    const secondType = googlePlaceTypes.pop()
    return camelCase(secondType)
  }
  return camelCase(firstType)
}

export const PLACE_TYPES = {
  DRMAX_BOX: 'DRMAX_BOX',
  PHARMACY: 'PHARMACY',
  PACZKOMAT: 'PACZKOMAT',
  PACKETA: 'PACKETA',
}

export const POINT_TYPES = {
  PHARMACY: 'pharmacy',
  POST_OFFICE: 'post-office',
  PACKETA: 'packeta',
  DRMAX_BOX: 'drmax_box',
  PICKUP_PLACE: 'pickup-place',
  PHARMACY_EXPRESS: 'pharmacy-express',
}

export const ORDER_TYPE = {
  ORDER: 'order',
  RESERVATION: 'reservation',
  RX_RESERVATION: 'rxReservation',
  RX_ORDER: 'rxOrder',
}

export const filteredByAll = (filters) => (places) => places.filter((place) => filters.every((filter) => filter(place)))

export const mappedByAll = (mappers) => (places) => {
  let mappedPlaces = places
  for (const mapper of mappers) {
    mappedPlaces = mappedPlaces.map(mapper)
  }
  return mappedPlaces
}

export const withinMapBounds = (mapBounds) => (places) => (mapBounds
  ? places.filter((place) => mapBounds.contains({ lat: place.latitude, lng: place.longitude }))
  : [])

export const distinctByDistanceFrom = (location) => (places) => places
  .map((place) => ({
    ...place,
    distance: getDistance(place, location),
  }))
  .sort((a, b) => a.distance - b.distance)
  .filter((place, index, sortedPlaces) => (index === 0 || place.pickupPlaceId !== sortedPlaces[index - 1].pickupPlaceId) ?? [])

export const nearestTo = (location) => (places) => {
  const distances = places.map((place) => getDistance(place, location))
  const nearestIndex = distances.indexOf(Math.min(...distances))
  return nearestIndex >= 0 ? places[nearestIndex] : null
}

/**
 * Maps error to formatted error messages for the user and for the console log
 * @param {string} method - method for which the error is processed, e.g. the function name getCart
 * @param {Object} ctx - vue instance for using translations
 * @param {Object} errorConfigForMethod - config object for error given by method, loaded from errorList.js
 * @param {boolean} isFetchError - was the error caused by api call failure? E.g. error 400 or 500
 */
const mapErrorToMessage = (method, ctx, errorConfigForMethod, isFetchError = false) => (error) => {
  const errConfig = errorConfigForMethod.errors.find((e) => {
    const messageMatchFunctionExists = isFunction(e.responseMessageMatch)
    const messageMatch = !messageMatchFunctionExists
      || (messageMatchFunctionExists && e.responseMessageMatch(error.message))
    const messageIncludes = !e.responseMessageContains
      || (e.responseMessageContains && error.message.includes(e.responseMessageContains))

    return messageIncludes && messageMatch
  })

  const errCode = errConfig?.code ?? errorConfigForMethod.defaultCode ?? 'gl000'

  let messagePrefix
  if (errConfig?.prefix === '') {
    messagePrefix = ''
  } else if (!errConfig?.prefix) {
    messagePrefix = `${ctx.$t('global.errors.Error #{errNum} occurred', { errNum: errCode })}: `
  } else {
    messagePrefix = `${ctx.$t(errConfig.prefix, { errNum: errCode })}: `
  }

  let message

  if (isFunction(errConfig?.message)) {
    message = errConfig.message(error.message)
  } else {
    message = errConfig?.message
      ? `${messagePrefix}${ctx.$t(errConfig.message)}`
      : `${ctx.$t('global.errors.Request failed', { url: ctx.$router.currentRoute.path })} (#${errCode})`
  }

  const defaultMessageApplied = !errConfig?.message

  const logMessage = isFetchError
    ? `Fetch Error (${errCode}) at ${method}: ${error.message}.`
    : `Error (${errCode}) at ${method}: ${error.message}.`

  return {
    message,
    logMessage,
    defaultMessageApplied,
    errCode,
  }
}

/**
 * @param {object} logger logger object, created by useLogger composable
 * @param {string} defaultErrorCode usually 000, e.g. ch000 for checkout
 *
 * Handles default error for the given method, if there is no specific error defined in the error config
 * param {string} method - method for which the error is processed, e.g. the function name getCart
 * param {string} defaultCode - default error code for the given method
 * param {Object} ctx - vue instance for using translations
 */
const handleDefaultError = (logger, defaultErrorCode = 'gl000') => (method, defaultCode, ctx) => {
  logger.warn(
    'Error config is not defined for method: "%s"',
    method,
  )

  return {
    formattedErrors: [{
      message: `${ctx.$t('global.errors.Request failed',
        { url: ctx.$router.currentRoute.path })} (#${defaultCode ?? defaultErrorCode})`,
    }],
  }
}

/**
 * @param {object} logger logger object created by useLogger composable
 * @param {function} errorListLoader file, where the errors are stored in the module
 * @param {string} defaultErrorCode
 *
 * Handles API/GQL error for method given by string name, that made the call
 * param {string} method - method for which the error is processed, e.g. the function name getCart
 * param {Object} errs - object of errors
 * param {Array} errs.errors - array of errors coming from api
 * param {boolean} errs.isFetchError - was the error caused by api call failure? E.g. error 400 or 500
 * param {Object} ctx - vue instance for using translations
 */
// eslint-disable-next-line max-len
export const handleErrorFactory = (logger, errorListLoader, defaultErrorCode = 'gl000') => async (method, { errors = [], isFetchError = false } = {}, ctx) => {
  const { errorList } = await errorListLoader()

  // map incoming errors
  const errorConfigForMethod = errorList[method]
  if (!errorConfigForMethod) {
    return handleDefaultError(logger, defaultErrorCode)(method, null, ctx)
  }

  const errorsMessages = errors.map(mapErrorToMessage(method, ctx, errorConfigForMethod, isFetchError))
  errorsMessages.forEach((errorMessage) => logger.error(errorMessage.logMessage))

  return {
    formattedErrors: errorsMessages,
  }
}

export const getShippingMethodIcon = (shippingMethod, pinType = 'icon', prototypeIcons = {}, defaultIcon = null) => {
  // find method icon in any of the pin sets, the map pin may be different, but only one of the method icons is used. It may be potentially be unset
  if (shippingMethod?.pins) {
    for (const pin of shippingMethod.pins) {
      const iconPath = pin.pins?.find(({ status }) => status === pinType)?.path
      if (iconPath) {
        return iconPath
      }
    }
  }

  if (pinType === 'loading') {
    return '/img/map/pin-loading.svg'
  }

  if (prototypeIcons[shippingMethod?.carrierCode]) {
    return prototypeIcons[shippingMethod.carrierCode]
  }

  return defaultIcon || prototypeIcons.default
}

export const getPinForPickupPlacePlace = (pickupPlaceCarriers, { pickupPlaceType, courierId }, pinType) => {
  const carrierForGivenPlace = pickupPlaceCarriers.find(({ types, courierId: carrierCourierId }) => {
    const courierIdMatches = !courierId || isNullish(courierId) || carrierCourierId === courierId

    return courierIdMatches && types.includes(pickupPlaceType)
  })

  return getShippingMethodIcon(carrierForGivenPlace, pinType)
}

class FetchError extends Error {
  constructor(message, response, { isTimeout = false } = {}) {
    super(message)
    this.response = response
    this._isTimeout = isTimeout
  }

  get isTimeout() {
    return this._isTimeout
  }

  get isFetchError() {
    return true
  }
}

export const fetchWithTimeout = (url, options, timeout = 5000, retry = false) => new Promise((resolve, reject) => {
  const controller = new AbortController()

  const timeoutTimer = setTimeout(() => {
    controller.abort()
  }, timeout)

  const { headers, body } = options

  const fetchOptions = {
    ...options,
    headers: headers['Content-Type'] || headers['content-type'] ? headers : { 'Content-Type': 'application/json', ...headers },
    body: (isObject(body) || isArray(body)) ? JSON.stringify(body) : body,
    signal: controller.signal,
  }

  fetch(url, fetchOptions)
    .then((response) => {
      resolve(response)
    })
    .catch(async (err) => {
      clearTimeout(timeoutTimer)

      if (err.name !== 'AbortError') {
        throw new FetchError('Request failed', err)
      }

      // Request was aborted by controller.abort()
      if (retry) {
        try {
          const response = await fetchWithTimeout(url, options, timeout, false)

          resolve(response)
        } catch (e) {
          reject(e)
        }
      } else {
        reject(new FetchError('Request failed', err, { isTimeout: true }))
      }
    })
})
