export default {
  apiKey: 'AIzaSyC1d8yO-s6JG95rQcsWQbdAr3eGV5c4Wxc',
  interfaceLanguage: 'it',
  defaultLocation: {
    // Piazza Maggiore, Bologna
    latitude: 44.49355,
    longitude: 11.34312,
    type: 'geolocation',
  },
}
