import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'

export default defineAppConfig({
  suggestGroups: [
    'products',
    'brands',
    'productLines',
    'categories',
    'advices',
    'articles',
  ],
  searchExactMatchGroups: [
    'brands',
    'categories',
    'productLines',
  ],
  searchPreferredGroups: [
    'brands',
    'categories',
    'productLines',
  ],
  searchTabs: [
    'products',
    'productLines',
    'brands',
    'categories',
    'advices',
    'articles',
  ],
  sortSearchResultsByAvailability: true,
  searchService: {
    tenant: 'IT',
    storeId: 1,
  },
  ...config,
})
