export default {
  orderModuleConfig: {
    deliveryGroupMapping: {
      pickup_place_cc: [
        {
          type: 'milestone',
          key: 'new',
          label: 'Confermato',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'Pagamento non finalizzato',
          text: "Il pagamento non è andato a buon fine. Se pensi si tratti di un errore, contatta l'assistenza clienti.",
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'In elaborazione',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'In elaborazione',
          text: '',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'packed',
          label: 'Pronto per la spedizione',
          text: '',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'Spedito',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'Pronto per il ritiro',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Consegnato',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Cancellato',
          text: '',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'Cancellato',
          text: "L'ordine non è stato consegnato/ritirato e sta ritornando al nostro magazzino principale.",
          variant: 'error',
          parent: 'canceled',
        },
      ],
      delivery: [
        {
          type: 'milestone',
          key: 'new',
          label: 'Confermato',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'Pagamento non finalizzato',
          text: "Il pagamento non è andato a buon fine. Se pensi si tratti di un errore, contatta l'assistenza clienti.",
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'In elaborazione',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'In elaborazione',
          text: '',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'packed',
          label: 'Pronto per la spedizione',
          text: '',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'Spedito',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Consegnato',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Cancellato',
          text: '',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'Cancellato',
          text: "L'ordine non è stato consegnato/ritirato e sta ritornando al nostro magazzino principale.",
          variant: 'error',
          parent: 'canceled',
        },
      ],
      'pharmacy_purchase': [
        {
          type: 'milestone',
          key: 'new',
          label: 'Confermato',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Consegnato',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Cancellato',
          text: '',
          variant: 'error',
          parent: '',
        },
      ],
    },
  },
}
