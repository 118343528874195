import { defuFn } from 'defu'
import { default as rootConfig } from 'drmax/runtime.config.mjs'
import { default as csp } from '@nsf/csp/nuxt.config.mjs'
import { default as base } from '@nsf/base/nuxt.config.mjs'
import { default as elasticsearch } from '@nsf/elasticsearch/nuxt.config.mjs'
import { default as algolia } from '@nsf/algolia/nuxt.config.mjs'
import { default as search } from '@nsf/search/nuxt.config.mjs'
import { default as apothekaWidget } from '@nsf/apotheka-widget/nuxt.config.mjs'
import { default as pickupSelect } from '@nsf/pickup-select/nuxt.config.mjs'
import { default as pickupSelectV2 } from '@nsf/pickup-select-v2/nuxt.config.mjs'
import { default as checkout } from '@nsf/checkout/nuxt.config.mjs'
import { default as catalog } from '@nsf/catalog/nuxt.config.mjs'
import { default as speedCurve } from '@nsf/speed-curve/nuxt.config.mjs'
import { default as gtm } from '@nsf/gtm/nuxt.config.mjs'
import { default as productRelationshipLoaderPlacement } from '@nsf/product-relationship-loader-placement/nuxt.config.mjs'
import { default as cms } from '@nsf/cms/nuxt.config.mjs'
import { default as clubs } from '@nsf/clubs/nuxt.config.mjs'
import { default as myAccount } from '@nsf/my-account/nuxt.config.mjs'
import { default as webChatWidget } from '@nsf/web-chat-widget/nuxt.config.mjs'

const inlineConfig = {}
export default /* #__PURE__ */ defuFn(rootConfig, csp, base, elasticsearch, algolia, search, apothekaWidget, pickupSelect, pickupSelectV2, checkout, catalog, speedCurve, gtm, productRelationshipLoaderPlacement, cms, clubs, myAccount, webChatWidget, inlineConfig)
