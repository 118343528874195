export default {
  agreementsSchema: [
    {
      label: 'offers and announcements',
      description: 'offers and announcements',
      agreements: [
        {
          label: 'Email',
          description: 'mail agreement description',
          channel: 'EMAIL',
          topic: 'ESHOP',
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: false,
          },
        },
        {
          label: 'SMS',
          description: 'sms agreement description',
          channel: 'SMS',
          topic: 'ESHOP',
          hasPhoneNumber: true,
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: false,
          },
        },
      ],
    },
    {
      label: 'offers and announcements',
      description: 'offers and announcements',
      agreements: [
        {
          label: 'Email',
          description: 'mail agreement description',
          channel: 'EMAIL',
          topic: 'ESHOP',
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: true,
          },
        },
        {
          label: 'SMS',
          description: 'sms agreement description',
          channel: 'SMS',
          topic: 'ESHOP',
          hasPhoneNumber: true,
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: true,
          },
        },
      ],
    },
    {
      label: 'offers and announcements to client program',
      description: 'agree to send client program',
      agreements: [
        {
          label: 'Email',
          description: 'loyalty mail agreement description',
          channel: 'EMAIL',
          topic: 'CP',
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: true,
          },
        },
        {
          label: 'Post',
          description: 'loyalty post agreement description',
          channel: 'POST',
          topic: 'CP',
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: true,
          },
        },
        {
          label: 'SMS',
          description: 'loyalty sms agreement description',
          channel: 'SMS',
          topic: 'CP',
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: true,
          },
        },
      ],
    }
  ]
}
