export default {
  pharmacySelect: {
    bookReceiptButtonEnabled: false,
    enabledMarkersClusterer: true,
    excludeRegionIds: [],
    mobileBreakpoint: 768,
    nearestPointersCount: 4,
    nearestPointersEnabled: true,
    nearestPointersWithMarkersCount: 3,
    nearestPointersZoomLevel: 9,
    pickupPlacesEnabled: false,
    reservationButtonEnabled: true,
    usePharmacyApi: true,
  },
}
