export const modules = [
  '@nsf/datadog',
  '@nsf/metrics',
  '@nsf/i18n',
  ...(!process.env.STORYBOOK_BUILD ? ['@nsf/csp'] : []),
  '@nsf/ui-library',
  '@nsf/base',
  '@nsf/elasticsearch',
  '@nsf/algolia',
  '@nsf/search',
  '@nsf/articles',
  '@nsf/article-preview',
  '@nsf/apotheka-web',
  '@nsf/apotheka-widget',
  '@nsf/pickup-select',
  '@nsf/pickup-select-v2',
  '@nsf/brands',
  '@nsf/checkout',
  '@nsf/homepage',
  '@nsf/catalog',
  '@nsf/speed-curve',
  '@nsf/special-offers',
  '@nsf/faq',
  '@nsf/gtm',
  '@nsf/product-relationship-loader-magento',
  '@nsf/product-relationship-loader-persoo',
  '@nsf/product-relationship-loader-placement',
  '@nsf/product-relationship',
  '@nsf/subscription',
  '@nsf/delivery-fe',
  '@nsf/sukl-widget',
  '@nsf/cms',
  '@nsf/tags',
  '@nsf/clubs',
  '@nsf/validations',
  '@nsf/cj',
  '@nsf/local-it',
  '@nsf/features',
  '@nsf/loyalty-page',
  '@nsf/my-account',
  '@nsf/web-chat-widget',
  '@nsf/my-account',
  '@nsf/convert',

  // health-check module should be the last one due to its startup probe which should check the whole app
  '@nsf/health-check',
]

export default { modules }
