export default {
  product: {
    alternateProductSeoDescription: false,
    defaultMaxQty: 1000,
    detail: {
      addAttributeSetToAttributes: false,
      apothekaWidgetEnabled: true,
      disableReviewSource: false,
      forcedAttributes: [],
      showFreeShippingValue: true,
      showLastUpdate: true,
      showLoggedPriceNote: false,
      showPriceInfo: true,
      showPriceNote: false,
      titleRowsSeparated: false,
      useFreeShippingThreshold: true,
      visibleAttributeCodes: [],
    },
    infoBoxEnabled: false,
    isAttributeSetShowed: false,
    reviewsEnabled: true,
    shortDescriptionShowedOn: [
      'productDetail',
    ],
    wrapperProductPimFilter: [
      'Temporary unavailable',
      'Visible',
      'Permanently unavailable',
      'Delisted - sold off',
      'Promo',
    ],
  },
}
