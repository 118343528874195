import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import features from './features.mjs'

export default defineAppConfig({
  ...features,
  apiKey: 'bb134945vbuirsfn1mb28kpq',
  dataLayerName: 'dataLayerV2',
  persooEnabled: true,

  persooOfferIdAlternatives: 'b4ed18f55e5f4efabe58d85f04f7f88a',
  persooOfferIdBasket: 'd18429ec3e944a8e9be48c224b5a6eba',
  persooOfferIdLastViewed: 'b364b72615d244c3ab1134e7a40f9bd0',
  persooOfferIdNotFound: 'efc0ea2e7617400dbdfdf4fa3969b4e5',
  persooOfferIdPreBasket: 'c0954731f3ff4a93a29635bf2888ea1e',

  persooPreBasketLimit: 6,
  persooNotFoundLimit: 6,
  ...config,
})
