import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import features from './features.mjs'

export default defineAppConfig({
  ...features,
  apiKey: 'bdb89a433b03d4be4bddbf4ac158b14d',
  analyticsApiKey: 'ba649e52af7038ae30e2966f568c1a68',
  ...config,
})
