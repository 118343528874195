const pickupInstructions = [
  {
    icon: 'shipping',
    fragments: [
      { text: 'Ordina online e ritira comodamente' },
      {
        text: 'presso questo punto di ritiro.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      { text: 'Non appena l\'ordine sarà pronto per il ritiro,' },
      {
        text: 'ti avviseremo via e-mail.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'availability-box',
    fragments: [
      { text: 'Potrai ritirare il tuo ordine' },
      {
        text: 'entro 72 ore dalla consegna.',
        isBold: true,
      },
    ],
  },
]

export default {
  orderLabel: '',
  reservationLabel: '',
  rxReservationLabel: '',
  unavailablePickupLabel: 'Punto non disponibile',
  selectedPlaceLabel: 'Punto di ritiro scelto',
  confirmButtonLabel: 'Seleziona punto',
  getTitle() {
    return this.title
  },
  getSubtitle() {
    return [this.location.street, this.location.city].filter(Boolean).join(', ')
  },
  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  pins: {
    enabled: 'pin-inpost-box.svg',
    disabled: 'pin-inpost-box-disabled.svg',
    selected: 'pin-inpost-box-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#FEC909',
  icon: '/img/delivery/inpost.svg',
}
