export default {
  features: {
    filterableAttributesEnabled: true,
    nsfSlotEnabled: false,
    priceInfoMayhem: false,
    robotsFromConfigEnabled: true,
    watchdogProductPriceLowerBoundValue: 0.01,
    reviewsDisclaimerEnabled: true,
  },
}
