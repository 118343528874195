import { ORDER_TYPE } from '../../utils/Utils.js'

const reservationPickupInstructions = [
  {
    icon: 'pharmacy',
    fragments: [
      { text: 'Ritiro in farmacia degli' },
      {
        text: 'ordini online.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      { text: 'Non appena il tuo ordine sarà pronto per il ritiro,' },
      {
        text: 'ti contatteremo via e-mail.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'Puoi ritirare il tuo ordine' },
      {
        text: 'entro 10 giorni dalla sua consegna in farmacia.',
        isBold: true,
      },
    ],
  },
]

export default {
  orderLabel: '',
  reservationLabel: 'Servizio di ritiro in farmacia',
  rxReservationLabel: 'Servizio di prenotazione farmaci con ricetta',
  unavailablePickupLabel: 'Ritiro in farmacia non disponibile',
  selectedPlaceLabel: 'Farmacia selezionata',
  confirmButtonLabel: 'Seleziona questa farmacia',
  getTitle() {
    return `Farmacia Dr.Max, ${this.title}`
  },
  getSubtitle() {
    return [this.location.street, this.location.city].filter(Boolean).join(', ')
  },
  getInstructions(orderType) {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: (() => {
        switch (orderType) {
          case ORDER_TYPE.RESERVATION:
            return reservationPickupInstructions
          default:
            return []
        }
      })(),
    }
  },
  pins: {
    enabled: 'pin-pharmacy-enabled.svg',
    disabled: 'pin-pharmacy-disabled.svg',
    selected: 'pin-pharmacy-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#3C951F',
  icon: '/img/delivery/cac.svg',
}
